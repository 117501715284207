/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: "Roboto", "Helvetica Neue", sans-serif; 
  color:#3d3d3d;
}

.mat-tab-label {
  border: 1px solid red;
}

/* #fafafa */
:root {
  --light-gray-bg: #fafafa;
  --global-shadow: 0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
}


/* globals */
.m-0 {
  margin:0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-10 {
  padding-top:10px !important;
}

.pt-15 {
  padding-top:15px !important;
}

.pt-20 {
  padding-top:20px !important;
}

.col-50 {
  display: inline-flex;
  width:50%;
}

/* 50/50 col */
.flex-parent-row {
  display: flex;
  width: 100%;
}

.col-50-flex {
  flex: 1;
}

.w-33 {
  width:33% !important;
}

.w-50 {
  width:50% !important;
}

.d-ifx {
  display: inline-flex;
}

h1 {
  font-size: 24px;
  color:#3d3d3d;
  font-weight: 100;
}

h2 {
  font-size: 22px;
  color:#3d3d3d;
  font-weight: 100;
}

h3 {
  font-size: 20px;
  color:#3d3d3d;
  font-weight: 100;
}

h4 {
  font-size: 18px;
  color:#3d3d3d;
  font-weight: 100;
}

h5 {
  font-size: 16px;
  color:#3d3d3d;
  font-weight: 100;
}

.inner-container {
  padding:35px 25px 25px 25px;
}

.grouping-container {
  margin-right: 25px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

/* ****** table styles ******** */
table {
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
}

th {
  height:40px;
  box-shadow: 9px 5px 11px -20px rgba(0, 0, 0, 0.1) !important;
}

tr:hover {
  background: #f8f6f6;
}

tr:active {
  background: #dddddd !important;
}

td {
  border-bottom: solid 1px #e8e8e8 !important;
}

.selecetedRow {
  background: #dddddd !important;
}

.online {
  color: #A0CC44;
}

.offline {
  color: #cc6544;
}


/* cards */
.card {
  width: 100%;
  padding: 5px 5px;
  margin: 10px 0px;
  border-radius: 30px;
  box-shadow:  0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
  background-color: #ffffff !important;
}

.card-min-height {
  width: 100%;
  padding: 5px 5px;
  margin: 10px 0px;
  border-radius: 30px;
  box-shadow:  0px 4px 23.1px 0px rgba(0, 0, 0, 0.14);
  min-height: 180px;
  max-height: 180px;
  background-color: #ffffff !important;
}