@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

// Note: Color palettes are generated from primary: #53A7FA
$_palettes: (
  primary: (
    0: #ffffff,
    10: #001d36,
    20: #003258,
    25: #003d6a,
    30: #00497d,
    35: #005590,
    40: #0061a4,
    50: #0b7bcb,
    60: #3c95e6,
    70: #63b0ff,
    80: #9ecaff,
    90: #d1e4ff,
    95: #eaf1ff,
    98: #ffffff, //bg of surfaces
    99: #fdfcff,
    100: #ffffff,
  ),
  secondary: (
    0: #53A7FA,
    // 0: #000000,
    10: #001d36,
    20: #14324f,
    25: #213d5b,
    30: #2d4967,
    35: #395473,
    40: #456080,
    50: #5e799a,
    60: #7893b5,
    70: #92add1,
    80: #adc9ed,
    90: #d1e4ff,
    95: #eaf1ff,
    98: #ffffff,
    99: #fdfcff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #330044,
    20: #520a68,
    25: #5e1b74,
    30: #6b2880,
    35: #78358d,
    40: #86429a,
    50: #a15bb5,
    60: #bd75d1,
    70: #da8fee,
    80: #f0b0ff,
    90: #fbd7ff,
    95: #ffebfe,
    98: #fff7fb,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #181c21,
    20: #2d3136,
    25: #383c41,
    30: #43474d,
    35: #4f5358,
    40: #5b5e65,
    50: #74777d,
    60: #8e9197,
    70: #a8abb2,
    80: #c4c6cd,
    90: #e0e2e9,
    95: #eef1f8,
    98: #f8f9ff,
    99: #fdfcff,
    100: #ffffff,
    4: #0b0e13,
    6: #101419,
    12: #1c2025,
    17: #272a30,
    22: #31353a,
    24: #36393f,
    87: #d7dae1,
    92: #e6e8ef,
    94: #f4f4f4,
    96: #f1f3fb,
  ),
  neutral-variant: (
    0: #000000,
    10: #151c25,
    20: #2a313a,
    25: #353c46,
    30: #404751,
    35: #4c535d,
    40: #585f69,
    50: #707883,
    60: #8a919d,
    70: #a5acb7,
    80: #c0c7d3,
    90: #f4f4f4,
    95: #eaf1fe,
    98: #fefefe, //background color
    99: #fdfcff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);


// Define the theme object.
$dsio-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  density: (
    scale: -1,
  )
));


// theme overrides
:root {
  @include mat.core-theme($dsio-theme);
  @include mat.all-component-themes($dsio-theme);
  --mat-sidenav-content-background-color: #fefefe;
}


// Button Styles 
.primary-btn {
  background-color:#53A7FA !important;
  border-radius: 25px !important;
  color: #fff !important;
}

.primary-btn[disabled] {
  opacity: .75;
  background-color: lightgray !important;
  color: #626262 !important;
}

.secondary-btn {
  background-color: transparent !important;
  border-radius: 25px !important;
  color: #53a7fa !important;
  border: none;
}

.secondary-btn[disabled] {
  opacity: .75;
  color: #626262 !important;
}

.secondary-solid-btn {
  background-color: #6D89A5 !important;
  border-radius: 25px !important;
  color: #fff !important;
}

.button-rounded {
  &.mat-button,
  &.mat-raised-button,
  &.mat-flat-button {
    border-radius: 25px;
  }
}

